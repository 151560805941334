<template>
  <div class="tag warning" v-if="status == 'WAITING'">{{ $t('status.Scheduled') }}</div>
  <div class="tag success" v-else-if="status == 'DOING'">{{ $t('status.Live') }}</div>
  <div class="tag danger" v-else-if="status == 'END'">{{ $t('status.Ended') }}</div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    status: String
  },
  data() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
