<template>
  <div class="news-detail">
    <div class="container">
      <el-row :gutter="16">
        <el-col :span="16">
          <div id="meeting"></div>
          <div v-if="detail" style="margin-top: -30px">
            <div>
              <video v-if="detail.fileUrl" class="detail-video" controls controlsList="nodownload">
                <source :src="detail.fileUrl" />
              </video>
              <div class="detail-video" v-else>
                <img :src="detail.coverImage" width="100%" />
              </div>
            </div>
            <div class="detail-content">
              <div v-html="getText(detail, 'content')"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="event-card" v-if="detail">
            <div class="title">{{ getText(detail, 'title') }}</div>
            <el-row type="flex" justify="space-between">
              <Tag :status="detail.status" />
              <el-popover placement="bottom" width="314" trigger="click" v-if="detail.fileList.length > 0">
                <div class="download-list">
                  <ul>
                    <li v-for="report in detail.fileList" :key="report.fileName">
                      <a :href="report.fileUrl" target="_blank" download>
                        <div class="name">{{ report.fileName }}</div>
                      </a>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" size="mini" slot="reference">{{ $t('fileDownload') }}</el-button>
              </el-popover>
            </el-row>
            <div class="time">
              <b>{{ $t('events.StartTime') }}：</b>{{ $t('BeijingTime') }} {{ detail.startTime }}
            </div>
            <div class="url" v-if="detail.meetingUrl">
              <b>{{ $t('events.Url') }}：</b> <a :href="detail.meetingUrl" target="_blank">{{ detail.meetingUrl }}</a>
            </div>
            <div class="qrcode" v-if="detail.qrCodeUrl">
              <b>{{ $t('events.Qrcode') }}：</b> <img :src="detail.qrCodeUrl" width="80" />
            </div>
            <div class="desc">
              <b>{{ $t('events.Topic') }}：</b>{{ getText(detail, 'meetingTheme') }}
            </div>
            <el-button class="btn-block" @click="orderEvent" v-if="detail.status == 'WAITING' && !detail.reserved">{{ $t('events.RSVP') }}</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Tag from '@/components/Tag'
import ZoomMtgEmbedded from '@zoomus/websdk/embedded'

export default {
  name: 'EventDetail',
  components: {
    Tag,
  },
  data() {
    return {
      detail: null,
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
    }
  },
  watch: {
    $route() {
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  destroyed() {
    this.leaveMeeting()
  },
  methods: {
    getData() {
      api.getActivity(this.$route.params.id).then((res) => {
        this.detail = res.data
        if (this.detail.status == 'DOING' && this.detail.sign) {
          this.startMeeting()
        }
      })
    },
    orderEvent() {
      api.orderEvent(this.detail.id).then((res) => {
        this.$message({
          message: this.$t('events.Success'),
          type: 'success',
        })
        this.getData()
      })
    },
    startMeeting() {
      const client = ZoomMtgEmbedded.createClient()
      let meetingSDKElement = document.getElementById('meeting')
      console.log(meetingSDKElement)
      const language = localStorage.getItem('locale') == 'zh' ? 'zh-CN' : 'en-US'

      client.init({
        debug: false,
        zoomAppRoot: meetingSDKElement,
        language: language,
        customize: {
          meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
        },
      })

      const userId = this.userInfo.id

      client
        .join({
          apiKey: process.env.VUE_APP_ZOOM_API_KEY,
          signature: this.detail.sign,
          meetingNumber: this.detail.meetingId,
          password: this.detail.meetingPassword,
          userName: this.userInfo.nickname,
          userEmail: this.userInfo.email,
          customerKey: userId.toString(),
        })
        .then((success) => {
          console.log('Join meeting success:', success)
        })
        .catch((error) => {
          this.leaveMeeting()
          console.log('Join meeting failed:', error)
        })
    },
    leaveMeeting() {
      const client = ZoomMtgEmbedded.createClient()
      client.leaveMeeting()
    },
  },
}
</script>

<style lang="scss" scoped>
.news-detail {
  position: relative;
}
video.detail-video {
  width: 100%;
  height: 420px;
  background: #000;
}
#meeting {
  position: relative !important;
  top: 0;
  left: 0;
  z-index: 999;
  margin-bottom: 30px;
}
</style>
